import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerTop from "../components/bannerTop"
import PropertyItem from "../components/properties/PropertyItem"
import FilterProperties from "../components/properties/FilterProperties"
import FeaturedProperties from "../components/properties/FeaturedProperties"

const Propiedades = props => {
  const [pathname, setPathname] = useState("")
  useEffect(() => {
    setPathname(window.location.href)
  }, [props])

  return (
    <Layout>
      <SEO title={`Encuentra tu propiedad`} pathname={pathname} />
      <BannerTop title="Portafolio de Propiedades" />
      <div className="container contents lisitng-grid-layout">
        <div className="row">
          <div className="span9 main-wrap">
            <div className="">
              <section className="listing-layout">
                <div className="list-container clearfix">
                  <PropertyItem {...props} />
                </div>
              </section>
            </div>
          </div>
          <div className="span3 sidebar-wrap">
            <aside className="sidebar">
              <section className="widget advance-search">
                <h4 className="title search-heading">
                  Buscar Propiedad<i className="icon-search"></i>
                </h4>
                <div className="as-form-wrap">
                  <FilterProperties />
                </div>
              </section>
              <FeaturedProperties quantity={10} />
            </aside>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Propiedades
